import type { OrderQuery } from '../model/Order';
import {
  fetchOrderProducts,
  type OrderProduct,
  type SelectedOrderProduct,
  type SelectedOrderProducts,
  type TripCO2CompensationService,
  type TripInsurance,
} from '../model/OrderProduct';
import { createTrip, fetchTripPricing, type Trip } from '../model/Trip';
import { ApiError, ApiResponse } from '../services/Api';
import { type BraintreePaymentMethod, fetchBrainTreeClientToken, verifyCardVia3DS } from '../services/Braintree';
import type { Country } from '../services/Country';
import { Logger } from '../services/Logger';
import Tracking from '../services/Tracking';

import type { Action, ReduxState } from './';
import { type CarDetailAction, CarDetailActionType } from './carDetail';
import { flashMessageError } from './session';

export interface OrderState {
  loading: boolean;
  updating: boolean;
  error: string;
  query: OrderQuery;
  region: Country;
  trip: Trip;
  tripInsuranceChoices: TripInsurance[];
  otherTripInsurances: (TripInsurance | TripCO2CompensationService)[];
  otherProducts: OrderProduct[];
  selectedMandatoryInsurance: number;
  selectedProducts: SelectedOrderProducts;
  client_token: string;
  useReward?: boolean;
  saleCode?: string;
  paymentMethod?: BraintreePaymentMethod;
  message?: string;
}

const initialState: OrderState = {
  loading: true,
  updating: false,
  error: null,
  query: null,
  region: null,
  trip: null,
  tripInsuranceChoices: [],
  otherTripInsurances: [],
  otherProducts: [],
  selectedMandatoryInsurance: null,
  selectedProducts: {},
  client_token: null,
};

export enum OrderActionType {
  SET_ORDER = 'order/set',
  UPDATE_ORDER = 'order/update',
  SET_TRIP = 'order/setTrip',
  ERROR = 'order/error',
  CLEAR = 'order/clear',
}

type OrderAction =
  | Action<OrderActionType.SET_ORDER, OrderState>
  | Action<OrderActionType.UPDATE_ORDER, DeepPartial<OrderState>>
  | Action<OrderActionType.SET_TRIP, Trip>
  | Action<OrderActionType.ERROR, OrderState>
  | Action<OrderActionType.CLEAR>;

export default function orderReducer(state = initialState, action: OrderAction | CarDetailAction): OrderState {
  if (action.type === OrderActionType.SET_ORDER) {
    return {
      ...state,
      ...action.payload,
    };
  }

  if (action.type === OrderActionType.UPDATE_ORDER) {
    return {
      ...state,
      ...action.payload,

      // @ts-expect-error TS2322: Type
      // {   hash: string;   chat_id: string;   state: TripState;   state_name: string;   type: TripType;   date_from: string;   date_to: string;   created: string;   confirm_to: string;   handover_from: string;   ... 32 more ...;   rating_skippable: DeepPartial<...>; }
      // is not assignable to type Trip<Vehicle>
      // Types of property vehicle are incompatible.
      // Type DeepPartial<Vehicle> is not assignable to type Vehicle
      // Property equipment is optional in type DeepPartial<Vehicle> but required in type Vehicle
      trip: {
        ...state.trip,
        ...action.payload.trip,
      },
      paymentMethod: action.payload.paymentMethod as BraintreePaymentMethod,
    };
  }

  if (action.type === OrderActionType.SET_TRIP) {
    return {
      ...state,
      updating: false,
      trip: action.payload,
    };
  }

  if (action.type === OrderActionType.ERROR) {
    return { ...action.payload };
  }

  if (action.type === OrderActionType.CLEAR) {
    return {
      loading: true,
      updating: false,
      error: null,
      query: null,
      region: null,
      trip: null,
      tripInsuranceChoices: [],
      otherTripInsurances: [],
      otherProducts: [],
      selectedMandatoryInsurance: null,
      selectedProducts: {},
      client_token: state.client_token,
    };
  }

  if (action.type === CarDetailActionType.SET) {
    return {
      loading: true,
      updating: false,
      error: null,
      query: {
        date_from: action.payload.date_from,
        date_to: action.payload.date_to,
      },
      region: action.payload.vehicle.country.alpha2_code,
      trip: action.payload,
      tripInsuranceChoices: [],
      otherTripInsurances: [],
      otherProducts: [],
      selectedMandatoryInsurance: null,
      selectedProducts: {},
      client_token: state.client_token,
    };
  }

  return state;
}

export function loadOrderDetail(hash: string, query: OrderQuery): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    const order: OrderState = { ...initialState, ...getState().order };

    try {
      order.query = query;
      const products = await fetchOrderProducts(hash, query);
      order.tripInsuranceChoices = products.tripInsuranceChoices;
      order.otherTripInsurances = products.otherTripInsurances;
      order.otherProducts = products.otherProducts;
      order.selectedMandatoryInsurance = products.selectedMandatoryInsurance;
      order.selectedProducts = products.selectedProducts;

      order.trip = await fetchTripPricing(hash, order.query, {
        selectedMandatoryInsurance: order.selectedMandatoryInsurance,
        selectedProducts: order.selectedProducts,
        useReward: false,
        saleCode: null,
      });
      if (!order.region) {
        order.region = order.trip.vehicle.country.alpha2_code;
      }
      if (!order.client_token) {
        const { client_token } = await fetchBrainTreeClientToken(order.trip.vehicle.country.alpha2_code);
        order.client_token = client_token;
      }
    } catch (error) {
      order.error = error.message;
    }
    order.loading = false;

    dispatch({ type: OrderActionType.SET_ORDER, payload: order });
  };
}

export interface OrderChangeData {
  selectedMandatoryInsurance?: number;
  selectedProduct?: SelectedOrderProduct;
  unselectedProduct?: keyof SelectedOrderProducts;
  useReward?: boolean;
  saleCode?: string;
}

export function loadOrderTripPricing(data: OrderChangeData): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    const previousState = getState().order;
    const order: OrderState = {
      ...previousState,
      selectedProducts: {
        ...previousState.selectedProducts,
      },
      updating: true,
    };
    if (data.selectedMandatoryInsurance !== undefined) {
      order.selectedMandatoryInsurance = data.selectedMandatoryInsurance;
    }
    if (data.selectedProduct !== undefined) {
      order.selectedProducts[data.selectedProduct.type] = {
        type: data.selectedProduct.type,
        id: data.selectedProduct.id,
        data: data.selectedProduct.data ?? {},
      };
    }
    if (data.unselectedProduct !== undefined) {
      delete order.selectedProducts[data.unselectedProduct];
    }
    if (data.useReward !== undefined) {
      order.useReward = data.useReward;
    }
    if (data.saleCode !== undefined) {
      order.saleCode = data.saleCode;
    }
    dispatch({ type: OrderActionType.UPDATE_ORDER, payload: order });

    try {
      const state: OrderState = getState().order;
      const trip = await fetchTripPricing(state.trip.vehicle.hash, state.query, {
        selectedMandatoryInsurance: state.selectedMandatoryInsurance,
        selectedProducts: state.selectedProducts,
        useReward: state.useReward,
        saleCode: state.saleCode,
      });
      dispatch({ type: OrderActionType.SET_TRIP, payload: trip });
    } catch (error) {
      if (error instanceof ApiResponse) {
        error.findAnyMessage();
      }
      dispatch(flashMessageError(error.message));
      dispatch({ type: OrderActionType.ERROR, payload: previousState });
    }
  };
}

export const updateOrder = (data: DeepPartial<OrderState>): ActionReturnType => ({
  type: OrderActionType.UPDATE_ORDER,
  payload: data,
});

export function applyDiscountToOrder(code: string): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    const state = getState().order;
    const nextState = { ...state, saleCode: code };
    nextState.trip = await fetchTripPricing(state.trip.vehicle.hash, state.query, {
      selectedMandatoryInsurance: state.selectedMandatoryInsurance,
      selectedProducts: state.selectedProducts,
      useReward: state.useReward,
      saleCode: code,
    });
    dispatch({ type: OrderActionType.SET_ORDER, payload: nextState });
  };
}

export function createTripOrder(paymentMethod: BraintreePaymentMethod): ThunkAction<ReduxState> {
  return async (dispatch, getState) => {
    const order = getState().order;
    try {
      let nonce: string;
      let deviceData: unknown;
      try {
        [nonce, deviceData] = await verifyCardVia3DS(order.client_token, paymentMethod, order.trip.price_total.amount);
      } catch (error) {
        Logger.default.error('Braintree 3D Secure failed', { error });
        dispatch(flashMessageError('order.paymentMethod.authentication.error'));
        throw error;
      }
      const trip = await createTrip(order.trip.vehicle.hash, order.query, {
        selectedMandatoryInsurance: order.selectedMandatoryInsurance,
        selectedProducts: order.selectedProducts,
        useReward: order.useReward ?? false,
        saleCode: order.saleCode,
        message: order.message,
        nonce,
        deviceData,
      });
      Tracking.track('BOOKING_PAYMENT_SUCCEEDED', trip);
    } catch (error) {
      if (error instanceof ApiError) {
        error.findAnyMessage();
        dispatch(flashMessageError(error.message));
      }
      throw error;
    }
  };
}

export const clearOrder = () => ({ type: OrderActionType.CLEAR });
