enum RouteEnum {
  // HOMEPAGE_WITHOUT_LANG = '/',
  HOMEPAGE = '/',
  RENT_CAR = '/rent-car',
  CAR_DETAIL = '/car-detail/:hash/:params?',
  ORDER = '/order/:hash',

  PUBLIC_PROFILE = '/user-profile/:hash/:tab?',

  ADD_CAR = '/add-car',
  CARS = '/cars/:hash?/:tab?',

  TRIPS = '/trips',
  TRIP_DETAIL = '/trip-detail/:hash',
  TRIP_DETAIL_HAND_IN = '/trip-detail/:hash/hand-in',
  TRIP_DETAIL_HAND_OUT = '/trip-detail/:hash/hand-out',
  TRIPS_HISTORY = '/trips/history/:canceled?',
  TRIPS_CANCELLED = '/trips/canceled',

  MY_PROFILE = '/my-profile/:tab?',
  PROFILE_GENERAL = '/profile/general',
  PROFILE_PICTURE = '/profile/picture',
  PROFILE_PAYMENT_METHOD = '/profile/payment-method',
  // PROFILE_COMPANY = "/profile/company",

  FAVORITES = '/my-favorites',

  USER_REGISTRATION = '/sign/registration',
  SIGN_IN = '/sign/in',
  SIGN_OUT = '/sign/out',
  SIGN_UP = '/sign/up',
  // SIGN_VERIFY_EMAIL = "/sign/verify-email",
  // SIGN_RESET_PASSWORD = "/sign/reset-password",
  // REMIND_PASSWORD = "?/remind-password",

  MESSAGES = '/messages/:chat?',

  NOT_FOUND = '/404-not-found',

  STATIC_ABOUT_US = '/about-us',
  STATIC_CONTACT = '/contact',
  STATIC_FAQ = '/faq',
  STATIC_CORPORATE_GOVERNANCE = '/corporate-governance',
  STATIC_PARTNERS = '/partners',
  STATIC_TERMS = '/terms',
  STATIC_PRIVACY_POLICY = '/privacy-policy',
  STATIC_OFFER = '/offer-car',
}

export enum ExternalLinks {
  ZENY_AUTA_PUJCUJI = 'https://zenyautapujcuji.cz',
}

export default RouteEnum;
