import React, { useContext } from 'react';

export class CampaignContext {
  public vehicleFeedFilter: string;
  constructor(
    public id: string | undefined,
    vehicleFeedFilter: string | undefined,
  ) {
    this.vehicleFeedFilter = vehicleFeedFilter ?? id?.toLowerCase();
  }

  public getCampaignPath(path: string): string {
    if (!this.id || path !== '/') return path;
    return `/${this.id}`;
  }
}

const context = React.createContext<CampaignContext>(null);

export function useCampaignContext() {
  return useContext(context);
}

interface CampaignProviderProps {
  campaign?: string;
  vehicleFeedFilter?: string;
}

export function CampaignProvider(props: React.PropsWithChildren<CampaignProviderProps>) {
  return (
    <context.Provider value={new CampaignContext(props.campaign, props.vehicleFeedFilter)}>
      {props.children}
    </context.Provider>
  );
}
