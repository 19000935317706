import type { Scope } from '@sentry/browser';

import { APP_VERSION } from '../config';
import { permission } from '../decorators';
import type { User } from '../model/User';

import type { LogAdapter } from './Logger';

export class SentryLogAdapter implements LogAdapter {
  private initialized = false;
  private instance?: typeof import('@sentry/browser');

  private static user?: UserScope = null;
  private static tags: Tags = {
    language: null,
    app_region: null,
    user_region: null,
  };
  private static context: Record<string, Record<string, unknown> | null> = {};

  @permission(
    typeof window !== 'undefined' &&
      typeof process.env.GATSBY_SENTRY_DSN === 'string' &&
      process.env.GATSBY_LOG_ENABLED === 'true',
  )
  public warning(message: string, data) {
    this.prepareContext(scope => {
      scope.setExtras(data);
      this.instance?.captureMessage(message, scope);
    });
  }

  @permission(
    typeof window !== 'undefined' &&
      typeof process.env.GATSBY_SENTRY_DSN === 'string' &&
      process.env.GATSBY_LOG_ENABLED === 'true',
  )
  public error(message: string | Error, data) {
    this.prepareContext(scope => {
      scope.setExtras(data);
      this.instance?.captureException(message, scope);
    });
  }

  private prepareContext(callback: (scope: Scope) => void): void {
    this.init().then(() => {
      this.instance?.withScope(scope => {
        scope.setUser(SentryLogAdapter.user);
        scope.setTags(SentryLogAdapter.tags);
        for (const [key, context] of Object.entries(SentryLogAdapter.context)) {
          scope.setContext(key, context);
        }
        callback(scope);
      });
    });
  }

  private async init() {
    if (this.initialized) return;
    try {
      this.initialized = true;
      this.instance = await import('@sentry/browser');
      this.instance.init({
        dsn: process.env.GATSBY_SENTRY_DSN,
        release: APP_VERSION,
        environment: process.env.GATSBY_ENV,
      });
    } catch {
      // ignore
    }
  }

  public static setUser(user: User): void {
    this.user = {
      id: user.hash,
      email: user.email,
    };
    this.tags.user_region = user.country?.alpha2_code;
  }

  public static clearUser(): void {
    this.user = null;
    this.tags.user_region = null;
  }

  public static setLanguage(language: string): void {
    this.tags.language = language;
  }

  public static setRegion(region: string): void {
    this.tags.app_region = region;
  }

  public static setContext(name: string, context: Record<string, unknown>): void {
    this.context[name] = context;
  }
}

interface UserScope {
  id: string;
  email: string;
}

interface Tags extends Record<string, string> {
  language: string;
  app_region: string;
  user_region: string;
}
