import type { FirebaseApp } from '@firebase/app';
import type { Auth } from '@firebase/auth';
import type {
  CollectionReference,
  DocumentData,
  DocumentReference,
  Firestore,
  FirestoreError,
  Query,
  Timestamp,
} from '@firebase/firestore';

import { PrivateApiV3 } from './Api';

const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
};

let firebaseApp: FirebaseApp;
let db: Firestore;
let auth: Auth;
let firestore: typeof import('@firebase/firestore');
let authorized = false;

export async function firebaseAuth() {
  if (authorized) return;

  const response = await PrivateApiV3.get<{ token: string }>('/user/firebase_token');
  const { token } = response.data;

  if (!firebaseApp) {
    const { initializeApp } = await import('@firebase/app');
    firestore = await import('@firebase/firestore');
    firebaseApp = initializeApp(firebaseConfig);
    db = firestore.getFirestore(firebaseApp);
    const { getAuth } = await import('@firebase/auth');
    auth = getAuth(firebaseApp);
  }

  const { signInWithCustomToken } = await import('@firebase/auth');
  await signInWithCustomToken(auth, token);

  authorized = true;
}

export async function firebaseDestroy() {
  if (!authorized) return;
  const { signOut } = await import('@firebase/auth');
  await signOut(auth);
  authorized = false;
}

export const timestampToDate = (timestamp: Timestamp): Date =>
  new firestore.Timestamp(timestamp.seconds, timestamp.nanoseconds).toDate();

const getResourcePath = (path: string): string => {
  const env = process.env.GATSBY_FIREBASE_ENV;
  if (typeof env === 'string' && env !== '') {
    path = `${env}_${path}`;
  }
  return path;
};

export const getCollection = (path: string): CollectionReference => firestore.collection(db, getResourcePath(path));

export const getDocument = (path: string, id: string): DocumentReference =>
  firestore.doc(db, getResourcePath(path), id);

export const getOnSnapshot = (
  reference,
  observer,
  error = (e: FirestoreError) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    e;
  },
) => firestore.onSnapshot(reference, observer, error);

const parse = c => {
  if ('orderBy' in c) return firestore.orderBy('timestamp');
  if ('where' in c && 'userId' in c) return firestore.where('userHash', '==', c.userId);
};

export const getQuery = (collectionRef: CollectionReference, constraints) =>
  firestore.query(collectionRef, parse(constraints));

export const getUpdateDoc = (ref: DocumentReference, data) => firestore.updateDoc(ref, { ...data });

export const getAddDoc = (collectionRef: CollectionReference, data) => firestore.addDoc(collectionRef, { ...data });

export type TDocumentData = DocumentData;
export type TDocumentReference = DocumentReference;
export type TQuery = Query;

interface OrderConstraint {
  orderBy: string;
}

interface WhereConstraint {
  where: string;
  userId: string;
}

export type Constraints = OrderConstraint | WhereConstraint;
