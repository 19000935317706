import type { FetchPaymentMethodsPayload } from 'braintree-web';

import { camelcaseKeys } from '../helpers';

import { PrivateApiV3 } from './Api';
import { Country, countryToFilter } from './Country';

export interface BraintreeClientToken {
  client_token: string;
}

export enum BraintreeCardType {
  VISA = 'Visa',
  MASTERCARD = 'MasterCard',
}

export interface BraintreePaymentMethod extends FetchPaymentMethodsPayload {
  nonce: string;
  details: {
    cardType: BraintreeCardType;
    lastFour: string;
    bin: string;
    expirationYear: string;
    expirationMonth: string;
  };
}

export interface UserAdditionalInformation {
  email: string;
  billingAddress: {
    givenName: string;
    surname: string;
    phoneNumber: string;
    streetAddress: string;
    extendedAddress?: string;
    locality: string;
    region?: string;
    postalCode: string;
    countryCodeAlpha2: string;
  };
  additionalInformation: {
    workPhoneNumber: string;
    shippingGivenName: string;
    shippingSurname: string;
    shippingPhone: string;
    shippingAddress: {
      streetAddress: string;
      extendedAddress: string;
      locality: string;
      region?: string;
      postalCode: string;
      countryCodeAlpha2: string;
    };
  };
}

export type BraintreeTokenizer = () => Promise<{ nonce: string }>;

let braintree: typeof import('braintree-web');
async function getBraintree(): Promise<typeof import('braintree-web')> {
  if (!braintree) {
    braintree = await import('braintree-web');
  }
  return braintree;
}

export async function getBraintreePaymentMethods(clientToken: string): Promise<BraintreePaymentMethod[]> {
  try {
    const braintree = await getBraintree();
    const client = await braintree.client.create({
      authorization: clientToken,
    });
    const vault = await braintree.vaultManager.create({ client });
    return (await vault.fetchPaymentMethods()) as BraintreePaymentMethod[];
  } catch (error) {
    console.log('Braintree Error:', error);
    return [];
  }
}

export async function getBraintreeDeviceData(clientToken: string) {
  try {
    const braintree = await getBraintree();
    const client = await braintree.client.create({
      authorization: clientToken,
    });
    const dataCollector = await braintree.dataCollector.create({ client });
    return dataCollector.deviceData;
  } catch {
    return undefined;
  }
}

export async function verifyCardVia3DS(clientToken: string, card: BraintreePaymentMethod, amount: number) {
  try {
    const response = await PrivateApiV3.get<UserAdditionalInformation>('/user/additional-information');
    const userAdditionalInformation = camelcaseKeys(response.data);
    const braintree = await getBraintree();
    const client = await braintree.client.create({
      authorization: clientToken,
    });
    const dataCollector = await braintree.dataCollector.create({ client });
    const threeDSecure = await braintree.threeDSecure.create({
      version: 2,
      client,
    });
    const payload = (await threeDSecure.verifyCard({
      amount,
      nonce: card.nonce,
      bin: card.details.bin,
      ...userAdditionalInformation,
      onLookupComplete(_, next) {
        next();
      },
    })) as unknown as braintree.ThreeDSecureVerifyPayload;
    if (!payload.liabilityShifted) return Promise.reject();
    return [payload.nonce, dataCollector.deviceData];
  } catch (error) {
    console.log('Braintree 3DS Error:', error);
    return Promise.reject();
  }
}

export async function fetchBrainTreeClientToken(country: Country = Country.CZ) {
  const response = await PrivateApiV3.get<BraintreeClientToken>(
    `/user/braintree_client_token/${countryToFilter(country)}`,
  );
  return response.data;
}

export async function pairCard(nonce: string, country: Country = Country.CZ) {
  await PrivateApiV3.post(`/user/braintree_payment_method/${countryToFilter(country)}`, { nonce });
}

export async function unpairCard(nonce: string, country: Country = Country.CZ) {
  await PrivateApiV3.delete(`/braintree/${countryToFilter(country)}`, {
    payment_method_nonce: nonce,
  });
}

export function formatPaymentMethod(paymentMethod: BraintreePaymentMethod): string {
  return `${paymentMethod.details.cardType} *${paymentMethod.details.lastFour}`;
}
