import { merge } from 'lodash';

import { type BankAccount, updateVehicleBankAccount } from '../model/BankAccount';
import {
  CardType,
  type Document,
  DocumentType,
  removeVehicleDocument,
  uploadVehicleCard,
  uploadVehicleDocument,
} from '../model/Document';
import type { VehicleParking } from '../model/Location';
import {
  AdditionalInfoType,
  createMyVehicle,
  fetchMyVehicleDetail,
  type MyVehicle,
  type MyVehicleBasicInfo,
  type MyVehicleDetail,
  type MyVehiclePrices,
  type MyVehicleTechnicalDetail,
  updateMyVehicleRegistrationAttributes,
} from '../model/MyVehicle';
import { updateVehicleLocation } from '../model/Vehicle';
import type { VehicleChecklist } from '../model/VehicleChecklist';
import { type DefaultPricesResponse, fetchVehicleDefaultPrices } from '../model/VehicleDetailPrices';
import { updateVehiclePrices, type VehiclePriceListItem } from '../model/VehiclePriceList';
import { fetchCebiaCheck } from '../services/Cebia';
import { toISOString } from '../services/DateTime';
import { Logger } from '../services/Logger';

import { MyCarsActionType, openMyVehicleDetail } from './cars';
import type { Action, ReduxState } from './index';

export interface CarRegistrationState {
  loading: boolean;
  error?: string;
  vehicle: MyVehicleDetail;
  checklist: VehicleChecklist;
  defaultPrices?: DefaultPricesResponse;
}

export enum CarRegistrationActionType {
  LOAD = 'carRegistration/load',
  SET_ERROR = 'carRegistration/error',
  UPDATE_VEHICLE = 'carRegistration/updateVehicle',
  SET_VEHICLE = 'carRegistration/setVehicle',
  ADD_PHOTO = 'carRegistration/photos/add',
  REMOVE_PHOTO = 'carRegistration/photos/remove',

  COMPLETE_STEP_BASIC = 'carRegistration/complete/basic',
  COMPLETE_STEP_TECHNICAL = 'carRegistration/complete/technical',
  COMPLETE_STEP_CARD = 'carRegistration/complete/registrationCard',
  COMPLETE_STEP_PRICELIST = 'carRegistration/complete/pricelist',
  COMPLETE_STEP_BANK_ACCOUNT = 'carRegistration/complete/bankAccount',
  COMPLETE_STEP_LOCATION = 'carRegistration/complete/location',
  COMPLETE_STEP_PHOTOS = 'carRegistration/complete/photos',
  EXIT = 'carRegistration/exit',
}

const initialState: CarRegistrationState = {
  loading: false,
  vehicle: null,
  checklist: {
    about_car: false,
    technical_card: false,
    price_list_and_account_number: false,
    car_address: false,
    car_pictures: false,
  },
};

type CarRegistrationAction =
  | Action<CarRegistrationActionType.LOAD, boolean | Partial<CarRegistrationState>>
  | Action<CarRegistrationActionType.SET_ERROR, string>
  | Action<CarRegistrationActionType.COMPLETE_STEP_BASIC, MyVehicleDetail>
  | Action<CarRegistrationActionType.UPDATE_VEHICLE, Partial<MyVehicleDetail>>
  | Action<CarRegistrationActionType.COMPLETE_STEP_TECHNICAL, Partial<MyVehicleDetail>>
  | Action<CarRegistrationActionType.SET_VEHICLE, MyVehicleDetail>
  | Action<CarRegistrationActionType.COMPLETE_STEP_CARD, DefaultPricesResponse>
  | Action<CarRegistrationActionType.COMPLETE_STEP_PRICELIST, VehiclePriceListItem[]>
  | Action<CarRegistrationActionType.COMPLETE_STEP_BANK_ACCOUNT, BankAccount>
  | Action<CarRegistrationActionType.COMPLETE_STEP_LOCATION, VehicleParking>
  | Action<CarRegistrationActionType.ADD_PHOTO, Document>
  | Action<CarRegistrationActionType.REMOVE_PHOTO, string>
  | Action<CarRegistrationActionType.COMPLETE_STEP_PHOTOS>;

export const actions = {
  start:
    (vehicleHash?: string): ThunkAction<ReduxState> =>
    async dispatch => {
      if (!vehicleHash) {
        dispatch({ type: CarRegistrationActionType.LOAD, payload: false });
        return;
      }
      try {
        dispatch({ type: CarRegistrationActionType.LOAD, payload: true });
        const vehicle = await fetchMyVehicleDetail(vehicleHash);
        let defaultPrices: DefaultPricesResponse;
        if (!vehicle.checklist.price_list_and_account_number) {
          defaultPrices = await fetchVehicleDefaultPrices(vehicleHash);
        }
        dispatch({
          type: CarRegistrationActionType.LOAD,
          payload: { vehicle, checklist: vehicle.checklist, defaultPrices },
        });
      } catch (error) {
        dispatch({
          type: CarRegistrationActionType.SET_ERROR,
          payload: error.message,
        });
      }
    },

  completeStepBasic:
    (verification: MyVehicleBasicInfo): ThunkAction<ReduxState> =>
    async dispatch => {
      const vehicle = await createMyVehicle(verification);
      vehicle.vin = verification.vin; // we need it for Cebia but API doesn't return it for security reasons
      dispatch({
        type: CarRegistrationActionType.COMPLETE_STEP_BASIC,
        payload: vehicle,
      });
    },

  callCebia: (): ThunkAction<ReduxState> => async (dispatch, getState) => {
    try {
      const { vehicle } = getState().carRegistration;
      const data = await fetchCebiaCheck({
        vin: vehicle.vin,
        license_plate: vehicle.attributes.spz,
        raid: vehicle.attributes.raid,
        country_code: vehicle.country.alpha2_code,
      });
      const next: DeepPartial<MyVehicleDetail> = {
        manufacturer_id: data.manufacturer?.id,
        manufacturer: data.manufacturer?.name,
        model_id: data.model?.model_id,
        model: data.model?.name,
        type: data.type,
        fuel: data.fuel_type,
        attributes: {
          custom_price: data.price ? Math.round(data.price.amount) : 0,
          manufacture_year: data.first_registration ?? new Date().toISOString(),
          total_weight: data.total_weight ?? 0,
          seats: data.seats ?? 5,
          fuel_tank_capacity: data.fuel_tank_capacity,
          power: data.power,
          consumption: data.consumption_mix,
          engine_size: data.engine_size,
          transmission: data.transmission,
        },
      };
      dispatch({
        type: CarRegistrationActionType.UPDATE_VEHICLE,
        payload: next,
      });
    } catch (error) {
      Logger.default.warning('Cebia error', { error });
    }
  },

  completeStepDetail:
    (detail: Partial<MyVehicleTechnicalDetail>, last?: boolean): ThunkAction<ReduxState> =>
    async (dispatch, getState) => {
      const { vehicle } = getState().carRegistration;

      let manufacturing_year: string;
      if (detail.manufacture_year) {
        const date = new Date();
        date.setFullYear(detail.manufacture_year, 1, 1);
        manufacturing_year = toISOString(date);
      }

      const response = await updateMyVehicleRegistrationAttributes(vehicle.hash, {
        model_id: detail.model_id,
        is_last_step: last,
        attributes: {
          price: detail.price,
          manufacturing_year,
          total_weight: detail.total_weight,
          type: detail.type,
          seats: detail.seats,
          fuel: detail.fuel,
          transmission: detail.transmission,
          engine_size: detail.engine_size,
          power: detail.power,
          fuel_tank_capacity: detail.fuel_tank_capacity,
          consumption: detail.consumption,
          electric_battery_capacity: detail.electric_battery_capacity,
          electric_charging_time: detail.electric_charging_time,
          electric_range: detail.electric_range,
          number_of_doors: detail.number_of_doors,
          trim_style: detail.trim_style,
          number_of_owners: detail.number_of_owners,
          first_registration: detail.first_registration ? toISOString(detail.first_registration) : undefined,
          non_european: detail.additional_info === AdditionalInfoType.NON_EUROPEAN,
          is_first_registration_in_pl: detail.additional_info === AdditionalInfoType.FIRST_REGISTRATION_IN_PL,
          is_imported_from_na: detail.additional_info === AdditionalInfoType.IMPORTED_FROM_NA,
        },
      });
      if (!last) {
        dispatch({
          type: CarRegistrationActionType.UPDATE_VEHICLE,
          payload: response,
        });
      } else {
        dispatch({
          type: CarRegistrationActionType.COMPLETE_STEP_TECHNICAL,
          payload: response,
        });
        try {
          const nextVehicle = await fetchMyVehicleDetail(vehicle.hash);
          dispatch({
            type: CarRegistrationActionType.UPDATE_VEHICLE,
            payload: nextVehicle,
          });
        } catch {
          // ignore
        }
      }
    },

  uploadCard:
    <T extends DocumentType.RC_FRONT | DocumentType.RC_BACK>(type: T, dataURI: string): ThunkAction<ReduxState> =>
    async (_, getState) => {
      const { vehicle } = getState().carRegistration;
      await uploadVehicleCard(vehicle.hash, CardType.REGISTRATION_CARD, type, dataURI);
    },

  completeStepCard: (): ThunkAction<ReduxState> => async (dispatch, getState) => {
    const { vehicle, defaultPrices: prices } = getState().carRegistration;
    let defaultPrices: DefaultPricesResponse;
    if (!prices) {
      defaultPrices = await fetchVehicleDefaultPrices(vehicle.hash);
    }
    dispatch({
      type: CarRegistrationActionType.COMPLETE_STEP_CARD,
      payload: defaultPrices,
    });
  },

  completeStepPricelist:
    (pricelist: MyVehiclePrices): ThunkAction<ReduxState> =>
    async (dispatch, getState) => {
      const { vehicle } = getState().carRegistration;
      const prices = await updateVehiclePrices(
        vehicle.hash,
        pricelist.price_per_day,
        pricelist.price_per_km,
        pricelist.flexible_discounts,
      );
      dispatch({
        type: CarRegistrationActionType.COMPLETE_STEP_PRICELIST,
        payload: prices,
      });
    },

  completeStepBankAccount:
    (account: BankAccount): ThunkAction<ReduxState> =>
    async (dispatch, getState) => {
      const { vehicle } = getState().carRegistration;
      await updateVehicleBankAccount(vehicle.hash, account);
      dispatch({
        type: CarRegistrationActionType.COMPLETE_STEP_BANK_ACCOUNT,
        payload: account,
      });
    },

  completeStepLocation:
    (location: VehicleParking): ThunkAction<ReduxState> =>
    async (dispatch, getState) => {
      const { vehicle } = getState().carRegistration;
      const response = await updateVehicleLocation(vehicle.hash, location);
      dispatch({
        type: CarRegistrationActionType.COMPLETE_STEP_LOCATION,
        payload: response,
      });
    },

  uploadPhoto:
    <T extends DocumentType>(type: T, dataURI: string): ThunkAction<ReduxState> =>
    async (dispatch, getState) => {
      const { vehicle } = getState().carRegistration;
      const document = await uploadVehicleDocument(vehicle.hash, type, dataURI);
      dispatch({
        type: CarRegistrationActionType.ADD_PHOTO,
        payload: document,
      });
      return document;
    },

  removePhoto:
    (hash: string): ThunkAction<ReduxState> =>
    async (dispatch, getState) => {
      const { vehicle } = getState().carRegistration;
      await removeVehicleDocument(vehicle.hash, hash);
      dispatch({ type: CarRegistrationActionType.REMOVE_PHOTO, payload: hash });
    },

  completeStepPhotos: (): ActionReturnType => ({
    type: CarRegistrationActionType.COMPLETE_STEP_PHOTOS,
  }),

  exitCarRegistration: (): ThunkAction<ReduxState, void> => (dispatch, getState) => {
    const { vehicle, checklist } = getState().carRegistration;
    dispatch({ type: CarRegistrationActionType.EXIT, payload: vehicle });
    if (Object.values(checklist).every(Boolean)) {
      dispatch(openMyVehicleDetail(vehicle.hash, true));
    }
  },
};

export default function carRegistration(
  state = initialState,
  action: CarRegistrationAction | { type: MyCarsActionType.SELECT; payload: MyVehicle },
): CarRegistrationState {
  if (action.type === MyCarsActionType.SELECT && state.vehicle?.hash !== action.payload.hash) {
    return { ...initialState };
  }

  if (action.type === CarRegistrationActionType.LOAD) {
    if (typeof action.payload === 'boolean') {
      return { ...initialState, loading: action.payload };
    }
    return merge({}, state, action.payload, { loading: false });
  }

  if (action.type === CarRegistrationActionType.SET_ERROR) {
    return { ...state, error: action.payload, loading: false };
  }

  if (action.type === CarRegistrationActionType.COMPLETE_STEP_BASIC) {
    return { ...state, vehicle: action.payload };
  }

  if (action.type === CarRegistrationActionType.UPDATE_VEHICLE) {
    return { ...state, vehicle: merge({}, state.vehicle, action.payload) };
  }

  if (action.type === CarRegistrationActionType.COMPLETE_STEP_TECHNICAL) {
    return merge({}, state, {
      vehicle: action.payload,
      checklist: { about_car: true },
    } as DeepPartial<CarRegistrationState>);
  }

  if (action.type === CarRegistrationActionType.SET_VEHICLE) {
    return { ...state, vehicle: action.payload };
  }

  if (action.type === CarRegistrationActionType.COMPLETE_STEP_CARD) {
    return merge({}, state, {
      checklist: { technical_card: true },
      defaultPrices: action.payload ?? state.defaultPrices,
    } as DeepPartial<CarRegistrationState>);
  }

  if (action.type === CarRegistrationActionType.COMPLETE_STEP_PRICELIST) {
    return merge({}, state, {
      vehicle: {
        prices: { price_list: action.payload },
      },
    } as DeepPartial<CarRegistrationState>);
  }

  if (action.type === CarRegistrationActionType.COMPLETE_STEP_BANK_ACCOUNT) {
    return merge({}, state, {
      vehicle: {
        attributes: { ...action.payload },
      },
      checklist: { price_list_and_account_number: true },
    } as DeepPartial<CarRegistrationState>);
  }

  if (action.type === CarRegistrationActionType.COMPLETE_STEP_LOCATION) {
    return merge({}, state, {
      vehicle: {
        locations: [action.payload],
      },
      checklist: { car_address: true },
    } as DeepPartial<CarRegistrationState>);
  }

  if (action.type === CarRegistrationActionType.ADD_PHOTO) {
    const nextState = { ...state };
    let documents = [...state.vehicle.documents];
    if (documents.some(it => it.type === action.payload.type)) {
      documents = documents.map(it => (it.type === action.payload.type ? action.payload : it));
    } else {
      documents.push(action.payload);
    }
    nextState.vehicle.documents = documents;
    if (action.payload.type === DocumentType.MAIN_PHOTO) {
      nextState.vehicle.main_image = action.payload as Document<DocumentType.MAIN_PHOTO>;
    }
    return nextState;
  }

  if (action.type === CarRegistrationActionType.REMOVE_PHOTO) {
    const documents = state.vehicle.documents.filter(it => it.hash !== action.payload);
    return { ...state, vehicle: { ...state.vehicle, documents } };
  }

  if (action.type === CarRegistrationActionType.COMPLETE_STEP_PHOTOS) {
    return merge({}, state, {
      checklist: { car_pictures: true },
    } as DeepPartial<CarRegistrationState>);
  }

  return state;
}
